<template>
  <div class="container">
    <div class="header">
    <div class="left-swiper">
        <el-carousel height="484px" class="custom-carousel" :autoplay="true" :loop="true" :interval="5000" direction="horizontal">
            <el-carousel-item v-for="(item,index) in banners" :key="index" >
                <img class="bannerImage" :src="item" alt="banner" width="860" height="484">
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="right-login">
        <div class="top">
            <div class="login-register">
                <img class="login-logo" src="../../../../public/images/home/login_logo.png" alt="">
                <span>欢迎来到天榜号平台</span>
            </div>
            <div class="buy-btn" @click="clickBuyBtnHandler()">
                <span>我要买号</span>
            </div>
            <div class="sell-btn" @click="clickSellBtnHandler()">
                <span>我要卖号</span>
            </div>
        </div>
        <div class="bottom">
            <div class="bottom-zhinan">
                <div class="item" @click="clickBuyersGuideBtn()">
                    <img src="../../../../public/images/home/icon_mjzn.png" alt="">
                    <span>买家指南</span>
                </div>
                <div class="splice"></div>
                <div class="item" @click="clickSellerGuideBtn()">
                    <img src="../../../../public/images/home/icon_mjzn2.png" alt="">
                    <span>卖家指南</span>
                </div>
            </div>
        <div class="bottom-help">
            <div class="item" @click="clickFAQBtn()">
                <img src="../../../../public/images/home/icon_cjwt.png" alt="">
                <span>常见问题</span>
            </div>
            <div class="splice"></div>
            <div class="item">
                <img src="../../../../public/images/home/icon_lxkf.png" alt="">
                <span>联系客服</span>
            </div>
        </div>
        </div>
        
    </div>
    </div>
  </div>
</template>

<script>
import {getHomeSettings} from '@/services/home/index.js';
import {mapMutations} from 'vuex';
export default {
     data(){
        return{
            banners:[]
        }
     },
     methods:{
        ...mapMutations("home",["setHomeSettings"]),
        async loadHomeSettings(){
            try{
                const {data} = await getHomeSettings();
                if(data.code==200){
                    this.setHomeSettings(data.data)
                    this.banners = Object.keys(data.data).filter(key=>key.startsWith('bannerUrl')).map(key=>data.data[key]);
                }
            }catch(err){}
        },
        //点击我要买按钮
        clickBuyBtnHandler(){
            this.$router.push({path:"/buyList"})
            this.$store.commit('home/setCurrentGlobalMenuIndex',1);
        },
        //点击我要卖按钮
        clickSellBtnHandler(){
            this.$router.push({path:'/publishProduct'})
            this.$store.commit('home/setCurrentGlobalMenuIndex',7);
        },
        //点击买家指南
        clickBuyersGuideBtn(){
            this.$router.push({path:"/helpCenter"})
            this.$store.commit('home/setCurrentGlobalMenuIndex',4);
        },
        //点击卖家指南
        clickSellerGuideBtn(){
            this.$router.push({path:"/helpCenter"})
            this.$store.commit('home/setCurrentGlobalMenuIndex',4);
        },
        //点击常见问题
        clickFAQBtn(){
            this.$router.push({path:"/helpCenter"});
            this.$store.commit('home/setCurrentGlobalMenuIndex',4);
        } 
         
    },
    created(){
        this.loadHomeSettings();
    }
}
</script>

<style lang="less" scoped>
.container{
    width: calc(100vw);
    height: 524px;
    background-image: url(https://hwj-games-market.oss-cn-shanghai.aliyuncs.com/background/content_header_bg_mini.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .header{
        margin: 0 auto;
        width: 1200px;
        height: 524px;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        .left-swiper{
            width: 860px;
            height: 484px;
            background: #FFFFFF;
            border-radius: 4px;
            .custom-carousel {
                border-radius: 4px; /* 设置圆角大小 */
            }
            .bannerImage{
                border-radius: 4px;
            }
        }
        .right-login{
            width: 320px;
            height: 484px;
            background-color:  #FFFFFF;
            border-radius: 4px;
            .top{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .login-register{
                    width: 288px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: default;
                    .login-logo{
                        margin-top: 30px;
                        width: 60px;
                        height: 60px;
                    }
                    &>span{
                        margin-top: 10px;
                        height: 16px;
                        font-size: 16px;
                        font-weight: bold;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        color: #000000;
                        line-height: 16px;
                    }
                }

                .buy-btn{
                    margin-top: 20px;
                    width: 288px;
                    height: 88px;
                    background-image: url(../../../../public/images/home/img_buy_bg.png);
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    position: relative;
                    cursor: pointer;
                    &>span{
                        position: absolute;
                        top: 36px;
                        right: 34px;
                        font-size: 20px;
                        font-weight: bold;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        color: #FFFFFF;
                    }
                }
                .sell-btn{
                    width: 288px;
                    height: 88px;
                    background-image: url(../../../../public/images/home/img_sell_bg.png);
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    position: relative;
                    cursor: pointer;
                    &>span{
                        position: absolute;
                        top: 36px;
                        right: 34px;
                        font-size: 20px;
                        font-weight: bold;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        color: #FFFFFF;
                    }
                }
            }

            .bottom{
                width: 320px;
                height: 170px;
                padding: 0 20px;
                .bottom-zhinan{
                    width: 280px;
                    height: 80px;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    border-bottom: 1px dashed #D8D8D8; 
                    .splice{
                        width: 1px;
                        height: 30px;
                        background: #ECECEC;
                    }
                .item{
                    width: 140px;
                    height: 60px;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap:nowrap;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    &>img{
                            width: 20px;
                            height: 20px;
                            margin-top: 20px;
                       }
                    &>span{
                        width: 48px;
                        height: 12px;
                        margin-top: 8px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei;
                        color: #666666;
                    }
                }
            }
            .bottom-help{
                width: 280px;
                height: 60px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                .splice{
                    margin-top: 10px;
                        width: 1px;
                        height: 30px;
                        background: #ECECEC;
                    }
                .item{
                    width: 140px;
                    // height: 44px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap:nowrap;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    &>img{
                            width: 20px;
                            height: 20px;
                            margin-top: 20px;
                       }
                    &>span{
                        width: 48px;
                        height: 12px;
                        margin-top: 8px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei;
                        color: #666666;
                    }
                }
            }
            }
           
        }
    }
}

</style>